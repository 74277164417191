import React from 'react'
import { FiChevronUp } from 'react-icons/fi'
import CancelPolicy from 'components/modules/CancelPolicy/CancelPolicy'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import SEO from 'components/elements/SEO/SEO'

const CancelPolicyTemplate = () => {
  const { t } = useTranslation()

  return (
    <>
      <SEO title={t('cancelPolicy.title')} />
      <div className="center-element h-padding pompeii-red-background" id="chi-siamo">
        <AniLink className="go-to-button white" cover direction="up" bg="#ffffff" duration={0.8} to="/">
          <FiChevronUp className="chevron" />
          <p>
            <Trans>{t('Home')}</Trans>
          </p>
        </AniLink>

        <CancelPolicy />
      </div>
    </>
  )
}

export default CancelPolicyTemplate
