import React from 'react'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

const CancelPolicy = () => {
  const { t } = useTranslation()

  return (
    <div className="cancelpolicy-container">
      <h2>
        <Trans>{t('cancelPolicy.title')}</Trans>
      </h2>
      <p>
        <Trans>{t('cancelPolicy.p1')}</Trans>
        <u>
          <a href="mailto:info@pompeiify.com" style={{ color: 'white' }}>
            info@pompeiify.com
          </a>
        </u>
        <Trans>{t('cancelPolicy.p2')}</Trans>
      </p>
      <ul>
        <li>
          <Trans>{t('cancelPolicy.li1')}</Trans>
        </li>
        <li>
          <Trans>{t('cancelPolicy.li2')}</Trans>
        </li>
        <li>
          <Trans>{t('cancelPolicy.li3')}</Trans>
        </li>
      </ul>
      <p>
        <Trans>{t('cancelPolicy.p3')}</Trans>
      </p>
    </div>
  )
}
export default CancelPolicy
